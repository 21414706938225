import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VuePageTransition from 'vue-page-transition'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import DatetimePicker from 'vuetify-datetime-picker' 
import '@mdi/font/css/materialdesignicons.css';
import VueColorPicker from '@duoa/vue-color-picker'
import '@duoa/vue-color-picker/dist/vue-color-picker.css'

Vue.use(VueColorPicker)
Vue.use(VuePageTransition)
Vue.use(DatetimePicker)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  icons: {
    iconfont: 'mdi',
  },
  render: h => h(App)
}).$mount('#app')
