<template>
  <div class="container mobcon">
    <h2 class="mt-5 text-center">Election Dashboard</h2>
    <v-data-table 
       :headers="headers" 
       :mobile-breakpoint="0" 
       :items="dataWithIndex"   
       :single-expand="singleExpand" 
       :expanded.sync="expanded" 
       hide-default-footer  
       class="playertable">
       <template v-slot:item="{ item,index,expand,isExpanded }"  >
        <tr>
          <td class="text-left">{{ index+1 }} </td>
          <td class="text-left">{{ item.name }} </td>
          <td class="text-center makeitflex">
             <div><v-btn v-if="item.template_created == 0" class="mr-2" :to="getUrl(item.url)" color="primary">Create Election</v-btn></div>
             <div><v-btn color="secondary" @click="expand(!isExpanded); getUnitsData(item,isExpanded,index)" class="actions bigbtn">See Result</v-btn></div>
          </td>
        </tr>
        
      </template>      
      <template v-slot:expanded-item="{ headers,item }">
        <td :colspan="headers.length" class="customtd">
          <div v-if="showloader" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div>
            <v-expansion-panels class="myexpansion">
              <template> 
                <v-expansion-panel v-for="(val,key) in item.units" :disabled="val.electionCompleted == 0" :key="key">
                  <v-expansion-panel-header>
                     <div class="d-flex myflex flexmob">
                       <div>{{ val.template_name }} </div>
                       <div>Votes / Voters:- &nbsp; {{ val.totalvotes }} / {{val.totalvoters }}</div>
                       <div>% of Votes Counted:- &nbsp; {{ val.votespercentage }}% </div>
                     </div>
                     
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                  
                     <div class="candidates" v-for="(inval,inkey) in val.candidates" :key=inkey>
                        <div class="firstblock">
                          <div class="logo" v-if="inval.logo"><img :src=inval.logo /></div>
                            <div>
                              <div>{{ inval.lastname }} {{ inval.firstname }}</div>
                              <div class="party">{{ inval.party }}</div>
                            </div>
                        </div>
                        <div class="secondblock">
                          <div>
                            <div class="text-center">Votes : {{ inval.votes }}</div>
                            <v-progress-linear v-if="!showOverallPercentage" color="orange" rounded :value=getvalue(inval.overallpercentage) height="15">
                             <span class="progress-bar-txt">{{ getvalue(inval.overallpercentage) }}%</span>
                            </v-progress-linear>
                            <v-progress-linear v-if="showOverallPercentage" color="orange" rounded :value=getvalue(inval.percentage) height="15">
                              <span class="progress-bar-txt">{{ getvalue(inval.percentage) }}%</span>
                             </v-progress-linear>
                        </div> 
                       
                        </div>
                     </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
             </v-expansion-panels> 
          </div>
        </td>
        
     </template>     
    </v-data-table>
  </div> 
</template>
<script>
export default{
  name: 'Electionlisting',
  data(){
    return{
       data:[],
       singleExpand: true,
       showloader:false,
       expanded: [],
       showOverallPercentage: true,
       headers:[
                  { text:"S.No",value: "",sortable: true, width:"8%"},
                  { text:"Election Name",value:"",sortable:false, width:"52%"},
                  { text:"Action", value: "",sortable: false, width:"30%"},
               ],
    }
  },
  mounted()
  {
     this.$store.dispatch("getmyelections").then((response) => 
     {
         if(response.status)
         {
           this.data = response.data;
         }
     });
  },
  computed: {
    dataWithIndex() 
    {  
      return this.data.map((items, index) => ({ ...items, index: index + 1 }));
    },
  },
  methods:{
    change(index,key){
      console.log(this.dataWithIndex[index].units[key].candidates);
      console.log(key);
    },
    getvalue(changed){
        return changed;
    },
    getUrl(url){
      return "/election-dashboard/"+url;
    },
    getUnitsData(item,isExpanded,index){
      if(!isExpanded)
      {
        this.showloader = true;
        this.$store.dispatch("getElectionData",{eid:item.id}).then((response) => 
        {
          this.data[index].units = response.data;
          this.showloader = false;
        });  
      }
     },
  }
}  
</script>
<style scoped>
.candidates {display: flex; margin-bottom: 10px;}
.candidates > div.firstblock{margin-right: 5px; flex: 0 0 50%;}
.candidates > div.secondblock{margin-right: 5px; flex: 0 0 50%;}
.candidates > div {display: flex;}
.candidates > div > div.logo{flex: 0 0 10%;}
.candidates > div img{width: 35px; }
.candidates  div.secondblock > div{flex: 1; margin-right: 10px;}
.party{font-size: 13px;}
.myflex{flex:1;justify-content: space-between;}
/*.myflex > div:nth-child(2){margin-right: 20px;}*/
.myflex > div{width: 33%;}
.myflex > div:nth-child(2){text-align: center;}
.myflex > div:nth-child(3){text-align: right; margin-right: 20px;}
.bigbtn{width: 170px;}
.makeitflex{ display: flex;  align-items: center;}
.mobcon{padding: 0;}
@media(max-width:768px)
{
   .makeitflex{display: block;height: inherit !important;}
   .makeitflex div{margin-top: 5px;margin-bottom: 5px;}
   .actions.ml-5{margin-left: 0 !important;}
   .bigbtn{width: 170px;}
}  
@media(max-width:767px)
{
  .candidates  div.secondblock{  display: block;  text-align: center;}
  .candidates  div.secondblock > div{margin-bottom: 5px;}
  .candidates > div{margin-right: 5px; flex: 0 0 60%;}
  .candidates div.secondblock{    flex: 0 0 40% !important;}
  .myflex.flexmob{display: block !important;}
  .myflex > div{ width: 100%; text-align: left !important; margin-bottom: 5px;}
  .candidates > div > div.logo{margin-right: 7px;}
}  
</style>
<style>
@media(max-width:767px)
{
.myexpansion .v-expansion-panel-content__wrap{padding: 0 10px 16px;}
}
</style>