<template>
  <div style="margin-top: 10%;" class="fill-height" :style="{ minHeight: containerHeight + 'px' }">
    <div class="container">
      <div class="filter">
         <div class="">
            <v-select v-model="region" hide-details outlined @change="getElections" :items="regions" label="Country" required></v-select>
         </div>
      </div>    
      
      <div class="items">
          <div v-if="region == ''" class="nodata">
           
             <div>Please choose country</div>
             <div>Or</div>
             <div>
                <v-btn color="secondary" @click="openRequest">Request for Election</v-btn>
             </div>
          </div>
          <div v-else>
             <div v-if="data.length == 0" class="nodata">No election right now</div>
             <div v-else class="mt-5">
                <div class="election-data" v-for="(val,key) in data" :key="key">
                   <div class="election-name">{{ val.election }}</div>
                   <div class="text-center" v-if="val.time != ''">
                     <strong>Eletion Start In</strong>
                  </div>
                  <div class="text-center" v-if="val.started == 1">
                     <strong>Eletion End In</strong>
                  </div>
                  <div class="text-center mt-3" v-if="val.time != ''">
                     <flip-countdown :deadline=val.time @timeElapsed="timeElapsedHandler(key)"></flip-countdown>
                  </div>
                  <div class="text-center mt-3" v-if="val.started == 1">
                    <flip-countdown :deadline=val.enddate ></flip-countdown>
                 </div>
                  <div v-if="val.started == 1" class="text-center mt-3">
                    <v-btn class="mybtn" color="primary" @click="vote(val.id)"><span>Vote</span></v-btn>
                  </div>
               </div>
             </div>
          </div>
      </div>      
    </div>
    <Loading :displayDialog="showdialog" />

    <div class="overlaycustom"  v-if="identitiesdialog">
      <div class="dialogcustom">
      <v-card>
        <v-toolbar color="primary" dark>
           <div v-if="!nototp">Confirm Your Identity</div>
           <div v-else>One Time Password</div>
        </v-toolbar>
        <v-card-text>
          <div class="mt-5" v-if="!nototp">
             <div v-for="(val,key) in identities" :key="key">
              
                <div class="mb-2">
                  <v-text-field v-if="val.dob == 0 && val.address == 0" hide-details outlined name="val" :label=val.fieldname  v-model="criteria[val.fieldname]"></v-text-field>
                  <div class="address-input-wrapper">
                    <v-text-field
                      hide-details
                      outlined
                      v-model="criteria[val.fieldname]"
                      @input="fetchAddresses(criteria[val.fieldname], val.fieldname)"
                      :label="val.fieldname"
                      v-if="val.address == 1"
                    />
                
                    <ul v-if="addresses.length && val.address == 1" class="address-list">
                      <li
                        v-for="(address, index) in addresses"
                        :key="index"
                        @click="selectAddress(address)"
                        class="address-item"
                      >
                        {{ address }}
                      </li>
                    </ul>
                  </div>
                  
                  <date-pick v-if="val.dob == 1" class="dobpicker" :format="'DD/MM/YYYY'"  v-model="criteria[val.fieldname]">
                    <template v-slot:default="{toggle}">
                      <v-text-field  
                        hide-details 
                        outlined  
                        :label=val.fieldname 
                        @click="toggle" placeholder="DD/MM/YYYY" v-model="criteria[val.fieldname]">
                      </v-text-field>
                     </template>
                   </date-pick>
                </div>
             </div>
             <div class="errorlog">{{ identityError }}</div>
          </div>
        
        <div  v-if="nototp">
          
            <otp  @value-sent="handleValueFromChild"/>
        
        </div>  
      </v-card-text> 
        <v-card-actions class="justify-space-between"  v-if="!nototp">
          <v-btn color="red" class="white--text" @click="closedialog">Close</v-btn>
          <v-btn color="green" class="white--text" @click="confirmIdentity">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    </div>
    <v-dialog  v-model="requestPopup"  max-width="600">
      <v-card>
        <v-toolbar color="primary" title="Election Form" class="white--text">Election Request Form</v-toolbar>
        <div class="small-container">
          
          <div class="myrow">
            <div class="label-div">Name</div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Name" placeholder="Name" v-model="formname"></v-text-field>
              <div class="errorcust" v-if="form.namerror != ''">{{ form.namerror }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Organisation</div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Organisation" placeholder="Organisation" v-model="formorganisation"></v-text-field>
              <div class="errorcust" v-if="form.organisationerror  != ''">{{ form.organisationerror }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Country</div>
            <div class="input-div">
              <v-select v-model="formcountry" hide-details outlined  :items="regions" label="Country" required></v-select>
              <div class="errorcust" v-if="form.countryerror  != ''">{{ form.countryerror }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Name of Election</div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Name of Election" placeholder="Name of Election" v-model="formelectionname"></v-text-field>
              <div class="errorcust" v-if="form.electionnameerror  != ''">{{ form.electionnameerror }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Phone Number</div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Phone" placeholder="Phone Number" v-model="formphone"></v-text-field>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Mobile Number </div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Mobile" placeholder="Mobile Number" v-model="formmobile"></v-text-field>
              
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Email </div>
            <div class="input-div">
              <v-text-field hide-details outlined label="Name" placeholder="Email" v-model="formemail"></v-text-field>
              <div class="errorcust" v-if="form.emailerror  != ''">{{ form.emailerror }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Password</div>
            <div class="input-div">
              <v-text-field hide-details type="password" outlined label="Name" placeholder="Password" v-model="formpassword"></v-text-field>
              <div class="errorcust" v-if="form.password  != ''">{{ form.password }}</div>
            </div>
          </div>
          <div class="myrow">
            <div class="label-div">Captcha</div>
            <div class="input-div">
              <!-- Google reCAPTCHA -->
              <div id="recaptcha" ref="recaptcha"></div>
            </div>
          </div>
          <div class="mt-5 mb-10 text-center">
             <v-btn color="secondary" :disabled="disableButton" @click="electionForm">Send</v-btn>
             <div class="mt-2 errorcust" :class="colorclass" v-if="showmsg != ''" v-html="showmsg"></div>
          </div>
        </div>
      </v-card>
   </v-dialog>    
    <v-overlay :value="templatePopup" opacity="0.5">
    
    <v-dialog content-class="ballot" class="myballot" :absolute="true" :style="dialogTopStyle" v-model="templatePopup" persistent >
      <v-card>
        <v-card-text style="max-height: 600px; overflow-y: auto;">
          <div class="ballot-template">
           <Template1 v-if="template == 'template1'" @data-sent="handleChildData" :preview=true :votetype=voteType :previewdata=childdata :showresult=true :vote=true />
           <Template2 v-if="template == 'template2'" @data-sent="handleChildData" :preview=true :votetype=voteType :previewdata=childdata :showresult=true :vote=true  />
           <div class="makecontainer" v-if="showsuccessvote">
              <div class="success-vote">Thank you for voting with myvoco.</div>
              <div class="clarity">Would you like a confirmation email that you have voted for your records?</div>
              <div class="confirmit">
                <v-radio-group @change="checkVoter" v-model="needcertificate">
                  <v-radio label="Yes" value="Yes"></v-radio>
                  <v-radio label="No" value="No"></v-radio>
                </v-radio-group>
              </div> 
              <div v-if="showemailBlock" class="mb-5">
                <v-text-field hide-details outlined name="Email" label="email" v-model="emailaddress"></v-text-field>
                <div class="errorlog">{{ identityError }}</div>
              </div>
              <div class="text-center">
                <v-btn type="button" :disabled=disableButton @click="confirmMyCertificate" color="secondary">Confirm</v-btn>  
              </div>
           </div>
         </div>
      </v-card-text>
      <v-card-actions class="ballotaction" v-if="showconfirmVotebtn">
        <v-btn type="button" :disabled=disableButton @click="confirmMyVote" color="secondary">Confirm</v-btn>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-overlay>
  </div>
</template>
<script>
//import Web3 from 'web3';
//import { blockchainConfig } from '../../blockchainConfig';
import Otp from '../components/Otp.vue';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { encryptionKey } from '../../config';
import Template1 from '../Templates/Template1.vue';
import Template2 from '../Templates/Template2.vue';
import Loading from '../components/Loader.vue';
import FlipCountdown from 'vue2-flip-countdown';
import DatePick from 'vue-date-pick';
 
export default {
  name: "Election",
  components:{
      Loading,
      FlipCountdown,
      Template1, 
      Template2,
      DatePick,
      otp: Otp,
      
  },
  data() {
    return {
        nototp: false,
        region: "",
        regions: ["America", "Australia", "United Kingdom","New Zeland"],
        showdialog:false,
        identitiesdialog:false,
        data:[],
        identities:[],
        criteria:{},
        identityError:'',
        electionid:0,
        templatePopup:false,
        template:'',
        childdata:[],
        voteType:'',
        showconfirmVotebtn:false,
        voteData:[],
        showsuccessvote:false,
        needcertificate:'',
        disableButton:false,
        showemailBlock:false,
        emailaddress:'',
        screenHeight: 0,
        screenWidth: 0,
        containerHeight:500,
        dialogTop: '100px',
        voter:'',
        pole:'',
        requestPopup:false,
        form:{namerror:'',organisationerror:'',electionnameerror:'',emailerror:'',password:'',countryerror:''},
        formname:'',
        formorganisation:'',
        formelectionname:'',
        formphone:'',
        formmobile:'',
        formemail:'',
        formpassword:'',
        formcountry:'',
        showmsg: '',
        captchaToken: null,
        colorclass:'',
        addresses: [],
        currentField: null
    }
  },
  
  computed: {
    dialogTopStyle() {
      // Define custom styles for top position
      return window.innerWidth <= 600 ? { top: '0' } : {};
    }
  },
  mounted() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    
    if(this.screenWidth <= 768)
    {
      
       if(this.screenHeight == 932)
         this.containerHeight = 600;
       else
         this.containerHeight = this.screenHeight - 200; 
    } 
    window.addEventListener('resize', this.updateScreenHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenHeight);
  },
  methods:{
   
  openRequest(){
      this.requestPopup = true;
      this.$nextTick(() => {
        this.initRecaptcha();
      });
      
  },
  initRecaptcha() {
    const recaptchaElement = this.$refs.recaptcha;
    if (!recaptchaElement) {
      console.error("❌ reCAPTCHA element not found!");
      return;
    }

    console.log("✅ reCAPTCHA element found, initializing...");

    let attempts = 0;
    const checkRecaptcha = setInterval(() => {
      if (window.grecaptcha && window.grecaptcha.render) {
        clearInterval(checkRecaptcha);
        console.log("✅ Rendering reCAPTCHA...");

        this.recaptchaWidget = window.grecaptcha.render(recaptchaElement, {
          sitekey: "6Lfqd-sqAAAAACa-nJeE96-Eqt-AiOIve46sa2A7", // Replace with your site key
          theme: "light",
        });
      } else {
        console.log(`🔄 Waiting for grecaptcha... (${++attempts})`);
        if (attempts > 10) {
          clearInterval(checkRecaptcha);
          console.error("❌ reCAPTCHA API failed to load.");
        }
      }
    }, 500);
  },
  async electionForm(){
     let error = 0
     if (window.grecaptcha && typeof window.grecaptcha.getResponse === "function") {
    const recaptchaResponse = window.grecaptcha.getResponse();

    if (!recaptchaResponse) {
      this.showmsg = "❌ Please complete the reCAPTCHA verification.";
      error = 1;
    }
  } else {
    this.showmsg = "⚠️ reCAPTCHA not loaded, please try again.";
    return;
  }
      if(this.formname == ''){
         this.form.namerror = 'Please enter name';
         error = 1;
      }
      else{
        this.form.namerror = '';
      }

      if(this.formorganisation == ''){
         this.form.organisationerror = 'Please enter organisation';
         error = 1;
      }
      else{
         this.form.organisationerror = '';
      }
      
      if(this.formelectionname == ''){
         this.form.electionnameerror = 'Please enter election name';
         error = 1;
      }
      else{
        this.form.electionnameerror = '';
      }

      // if(this.formphone == ''){
      //    this.form.phoneerror = 'Please enter phone number';
      //    error = 1;
      // }
      // else{
      //    this.form.phoneerror = '';
      // }

      if(this.formemail == ''){
         this.form.emailerror = 'Please enter email';
         error = 1;
      }
      else{
        this.form.emailerror = '';
      }

      if(this.formpassword == ''){
         this.form.password = 'Please enter password'
         error = 1;
      }
      else{
         this.form.password = '';
      }

      if(this.formcountry == ''){
         this.form.countryerror = 'Please select country';
         error = 1;
      }
      else{
        this.form.countryerror = '';
      }

      if(error == 0)
      {
        this.disableButton = true;
        let encryptedData = {
          country:this.formcountry,
          formname:this.formname,
          formorganisation:this.formorganisation,
          formelectionname:this.formelectionname,
          formphone:this.formphone,
          formemail:this.formemail,
          formpassword:this.formpassword,
          formmobile:this.formmobile,
          
        };
        axios.post('/election-request',{ myData: encryptedData}).then(response => 
        {
          this.disableButton = false; 
          if(response.data.status)
          {
            this.formname = '';
            this.formorganisation = '';
            this.formelectionname = '';
            this.formphone = '';
            this.formemail = '';
            this.formpassword = '';
            this.formmobile = '';
            this.showmsg = response.data.msg;
            this.colorclass = "text--green";
            setTimeout(() => {
              this.requestPopup = false;
              this.colorclass = '';
            }, 2000);
          }
          else
          {
            this.colorclass = '';
            this.showmsg = response.data.message;
          }
        });  
      }
  },
  isFutureDate(date) {
    const currentDate = new Date();
    return date < currentDate;
  },
  updateScreenHeight(){
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if(this.screenWidth <= 768)
    {
      this.containerHeight = this.screenHeight - 200;
    } 
  },
  getElections()
  {
    this.showdialog = true;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
              country:this.region,
              timezone: userTimezone
            }; 
    this.disableButton = true;
    var encryptedData = this.encryptMe(data);
    this.$store.dispatch("getElectionsData",{ data:encryptedData }).then((response) =>
    {
       let encryptStr = response.data;
       let decrypted = this.decryptMe(encryptStr);
       let finalresult = this.unserializePhpArray(decrypted);
          
       if(response.status)
       {
          this.data = finalresult;
       }
       this.showdialog = false;
    });
  },
  timeElapsedHandler(key)
  {
      this.data[key].started = 1;
      this.data[key].time = '';
  },
  vote(id)
  {
      this.showdialog = true;
      this.electionid = id;
      this.$store.dispatch("getElectionFields",{eid:id}).then((response) => 
      {
          this.showdialog = false;
          if(response.status)
          {
            this.identitiesdialog = true;    
            // this.identities = response.data;
            let encryptStr =  response.data;
            let decrypted = this.decryptMe(encryptStr);
            let finalresult = this.unserializePhpArray(decrypted);
            this.identities = finalresult;
          }
      })
  },
  handleValueFromChild(value) {
      if(value == "correct")
      {
          this.identitiesdialog = false;    
          this.templatePopup    = true;
          this.nototp = false;
      }
  },
  fetchAddresses(query, fieldname) {
      if (!query || query.length < 2) {
        this.addresses = []; // Clear suggestions if query is too short
        return;
      }
      this.currentField = fieldname;
      const data = {
        criteria: JSON.stringify(query),
        eid: this.electionid,
      };
      var encryptedData = this.encryptMe(data);
      axios.post('/getaddress', { data: encryptedData })
        .then(response => {
          this.addresses = response.data;
        })
        .catch(error => {
          console.error("Error fetching addresses:", error);
        });
    },

    selectAddress(address) {
    if (this.currentField) {
      this.criteria[this.currentField] = address; // Fill the correct input field
      this.addresses = []; // Hide the dropdown after selection
      this.currentField = null; // Reset after selection
    }
  },
  confirmIdentity()
  {
     var checkError = 0;
     this.identityError = '';
     for(var i = 0; i < this.identities.length; i++)
     {
       if(typeof this.criteria[this.identities[i]['fieldname']] == 'undefined')
       {
         checkError = 1;
      }
      else
      {
         if(this.criteria[this.identities[i]['fieldname']].trim() == '')
         {
            checkError = 1;
         }
      }
     }
       if(checkError == 1)
       {
         this.identityError = 'All fields are mandatory';
       }
       else
       {
         /* let formData = new FormData();
          formData.append('criteria', JSON.stringify(this.criteria));
          formData.append('eid', this.electionid);
          let encryptedData = this.encrypt(formData);*/
          // Prepare the data to be encrypted
          const data = {
                criteria: JSON.stringify(this.criteria),
                eid: this.electionid
            }; 
          this.disableButton = true;
          var encryptedData = this.encryptMe(data);
          axios.post('/search-my-vote',{ myData: encryptedData}).then(response => 
          {
             this.disableButton = false;
             if(response.data.status)
             {
                this.identityError    = '';
                //this.identitiesdialog = false;    
                //this.templatePopup    = true;
                this.nototp = true;
                let encryptStr        = response.data.data;
                let decrypted         = this.decryptMe(encryptStr);
                let finalresult       = this.unserializePhpArray(decrypted);
                
                if(finalresult.template != '')
                {
                    this.voter                   = finalresult.voter;
                    this.template                = finalresult.template;
                    this.childdata['color']      = finalresult.template_data.color;
                    this.childdata['data']       = finalresult.template_data.data;
                    this.childdata['note']       = finalresult.template_data.note;
                    this.childdata['candidates'] = finalresult.candidates;
                    this.voteType                = finalresult.votetype;
                    this.pole                    = finalresult.pole;
                }
              }
              else
              {
                  this.identityError = response.data.msg;
              }
            }).catch(error => {
                console.log(error);
                this.disableButton = false;
                this.makeitdisable = false;
            })   
         
       }
    },
    async confirmMyVote(){
      /*
      let formData = new FormData();
          formData.append('eid', this.electionid);
          formData.append('criteria', JSON.stringify(this.criteria));
          formData.append('vote', JSON.stringify(this.voteData));
      */
   

      const data = {
                criteria: JSON.stringify(this.criteria),
                eid: this.electionid,
                vote: JSON.stringify(this.voteData),
                voter: this.voter,
                pole: this.pole
            }; 
        
          var encryptedData = this.encryptMe(data);
          this.disableButton = true;
          axios.post('/do-my-vote', { myData: encryptedData}).then(response => 
          {
            this.disableButton = false;
            if(response.data.status)
            {
               this.showconfirmVotebtn = false;
               this.showsuccessvote = true;
               this.template = '';
            }
          }).catch(error => {
            console.log(error);
          });
    },
    checkVoter()
    {
      if(this.needcertificate == 'Yes')
       {  
         this.showemailBlock = true;
       }
      else
       {
         this.showemailBlock = false;  
         //this.makeAllReset();
       }  
    },
    encryptMe(data){
      let iv = CryptoJS.lib.WordArray.random(16);
      let key = CryptoJS.enc.Base64.parse(encryptionKey);
      let options = {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      };
      let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, options);

      encrypted = encrypted.toString();
      iv = CryptoJS.enc.Base64.stringify(iv);
      let result = {
            iv: iv,
            value: encrypted,
            mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
        }
      result = JSON.stringify(result);
      result = CryptoJS.enc.Utf8.parse(result);
      let encryptedData = CryptoJS.enc.Base64.stringify(result);
      
      return encryptedData;
    },
    decryptMe(encryptStr){
      let encryptStrVal = CryptoJS.enc.Base64.parse(encryptStr);
      let encryptData = encryptStrVal.toString(CryptoJS.enc.Utf8);
          encryptData = JSON.parse(encryptData);
      let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
      var decrypted = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(encryptionKey), {
            iv : iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
      decrypted = CryptoJS.enc.Utf8.stringify(decrypted); 
      
      return decrypted;
    },
   
    unserializePhpArray(serializedString) 
    {
      try {
        const start = serializedString.indexOf(':"');
        const jsonString = serializedString.substring(start + 2, serializedString.length - 2);
        const result = JSON.parse(jsonString);
        
        return result;
      } catch (error) 
      {
        console.error("Error deserializing PHP array:", error);
        return {}; // Return an empty object in case of error
       }
    },
    closedialog()
    {
      this.identitiesdialog = false;
    },
    handleChildData(data) {
       if(data.showconfirm == 1)
       {
         this.showconfirmVotebtn = true;
         this.voteData = data.vote;
       }
       else{
        this.showconfirmVotebtn = false;
       }
    },
    makeAllReset(){
      this.template = '';
      this.templatePopup = false;
      this.showsuccessvote = false;
      this.showemailBlock = false;  
      this.criteria = {};
      this.electionid = '';
      this.identityError = '';
      this.emailaddress = '';
      this.needcertificate = '';
    },
    confirmMyCertificate()
    {
       let error = 0;
       if(this.needcertificate == 'Yes' && this.emailaddress == '')
       {  
         this.showemailBlock = true;
         this.identityError = 'Please provide email';
         error = 1;
       }
       if(this.needcertificate == 'No')
       {
          this.makeAllReset();
       }
       
       if(error == 0 && this.needcertificate == 'Yes')
       {
          /*let formData = new FormData();
            formData.append('eid', this.electionid);
            formData.append('criteria', JSON.stringify(this.criteria));
            formData.append('email', this.emailaddress);*/
            const data = {
                criteria: JSON.stringify(this.criteria),
                eid: this.electionid,
                email: this.emailaddress
            }; 
        
          var encryptedData = this.encryptMe(data);
            this.disableButton = true;
            axios.post('/my-vote-certificate', { myData: encryptedData}).then(response => 
            {
              this.disableButton = false;
              if(response.data.status)
              {
                 this.makeAllReset();
              }
            }).catch(error => {
              console.log(error);
            });
       }
    }
  }   
} 
</script>
<style scoped>
.errorlog{color: red; font-size: 14px;}
.filter{ width: 45%; margin: auto; border-radius: 8px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 15px; margin-bottom: 25px; margin-top: 10px;}
.nodata{text-align: center; font-weight: bold; margin-top: 10%;}
.election-data{min-height: 65px; padding: 15px; margin-top: 15px; border-radius: 8px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
.election-name { margin-bottom: 5px; font-size: 19px;}
.makecontainer {
  background: white;
  padding: 10px 10px;
  color: black;
}
.success-vote {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 18px;
}

.small-container {
  width: 90%;
  margin: auto;
  padding: 10px;
}
.myrow {display: flex; align-items: center; margin-top: 15px;}
.label-div {width: 30%; font-size: 18px;}
.input-div {width: 70%;}
.errorcust{color: red; font-size: 14px;}
@media only screen and (max-width: 600px) {
  .filter{width: 100%;}
 
}

.overlaycustom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.overlaycustom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Dialog styling */
.dialogcustom {
  background-color: white;
  border-radius: 8px;
  text-align: left;
  position: absolute;
  top: 40%; /* Adjust this value to move it higher */
  left: 50%;
  transform: translate(-50%, -50%); /* Centering */
  width: 350px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.address-input-wrapper {
  position: relative; /* This ensures the dropdown positions relative to this container */
  width: 100%;
}

/* Address suggestions container */
.address-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px; /* Keeps the list from growing too large */
  overflow-y: auto; /* Allows scrolling if needed */
  position: absolute;
  width: 100%;
  z-index: 1000; /* Ensures it appears above other elements */
  top: calc(100% + 5px); /* Positions it directly below the input field with some spacing */
  left: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for better visibility */
}

/* Individual address item styling */
.address-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

/* Hover and active states for address items */
.address-item:hover {
  background-color: #f0f0f0;
}

.address-item:active {
  background-color: #e0e0e0;
}

.address-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

/* Optional: Error message styling */
.errorlog {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
<style>
.v-dialog.ballot{box-shadow:none !important;}
.v-dialog.ballot > .v-card > .v-card__text{padding: 0;}
.v-dialog.ballot > .v-card > .v-card__actions{padding: 0;}
.v-dialog.ballot .theme--light.v-card{background-color: transparent;}
.v-dialog .ballotaction{flex: 1; justify-content: center; margin-top: 5px;}
@media only screen and (max-width: 600px) {
  .filter{width: 100%;}
  .v-dialog.ballot{margin: 0;}
  .v-dialog__content {
    top: unset; /* Unset the fixed position on larger screens */
  }
}
@media(max-width: 430px) { 
.v-dialog__content {  align-items:flex-start; top: 6%; }
}

@media(max-width: 380px) { 
  .v-dialog__content {  top: 8%; }
  }
.errorcust.text--green{color:green !important}
</style>