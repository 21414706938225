<template>
  <div class="">
    <div ref="scrollToElement">&nbsp;</div>
    <div class="election-heading" v-if="data.election != '' && (first || third || fourth)">{{ data.election }}</div>
   
     <div class="container" v-if="first">
        <div class="election-dashboard">
          <div class="first-part" >
            <div class="myrow">
              <div class="mycolfirst">Country</div>
              <div class="mycolsecond">
                 <v-select :items="countries" item-text="name" label=" Country" v-model="country" hide-details  disabled outlined></v-select>
              </div>
           </div>
           <div class="myrow">
            <div class="mycolfirst">Timezone</div>
            <div class="mycolsecond">
               <v-select :items="timezoneList" item-text="name" label=" Timezone" v-model="timezone" hide-details   outlined></v-select>
            </div>
         </div>
           <div class="myrow">
            <div class="mycolfirst">Start Date</div>
            <div class="mycolsecond">
              <date-pick class="dobpicker" :format="'DD/MM/YYYY'"  :isDateDisabled="isFutureDate" v-model="electionstartdate">
                <template v-slot:default="{toggle}">
                  <v-text-field  
                    hide-details 
                    outlined  
                    label="Start Date" 
                    @click="toggle" placeholder="DD/MM/YYYY" v-model="electionstartdate">
                  </v-text-field>
                 </template>
               </date-pick>
               <div class="errorclass" v-if="error.electionstartdate">{{ error.electionstartdate }}</div>
            </div>
           </div>
           <div class="myrow">
            <div class="mycolfirst">End Date</div>
            <div class="mycolsecond">
              <date-pick class="dobpicker" :format="'DD/MM/YYYY'" :isDateDisabled="isFutureDate" v-model="electionenddate">
                <template v-slot:default="{toggle}">
                  <v-text-field  hide-details outlined  label="End Date" @click="toggle" placeholder="DD/MM/YYYY" v-model="electionenddate">
                  </v-text-field>
                 </template>
               </date-pick>
            </div>
           </div>
           <div class="myrow">
            <div class="mycolfirst">Start Time</div>
            <div class="mycolsecond">
              <vue-timepicker v-model="starttime" format="hh:mm A"></vue-timepicker>
              <div class="errorclass" v-if="error.electionstarttime">{{ error.electionstarttime }}</div>
            </div>
           </div>
           <div class="myrow">
            <div class="mycolfirst">End Time</div>
            <div class="mycolsecond">
              <vue-timepicker v-model="endtime" format="hh:mm A"></vue-timepicker>
              <div class="errorclass" v-if="error.electionendtime">{{ error.electionendtime }}</div>
            </div>
           </div>
           <div class="text-right mt-5">
             <v-btn type="submit" @click="next('second')" :disabled="makeitdisable" color="secondary">Next</v-btn>
           </div>
          </div>
        </div>
     </div>
     <div class="main-container" v-if="second">
      <div class="sidebar">
        <v-expansion-panels class="myexpansion">
          <v-expansion-panel>
            <v-expansion-panel-header> Choose Ballot Paper Template </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="template-imgs">
                <div class="temp-img" @click="showtemplate('template1','template1.png','static','')" :class="{ 'clicked': template == 'template1' }">
                  <img src="../assets/template1.png" />
                </div>
                <div class="temp-img" @click="showtemplate('template2','template2.png','static','')" :class="{ 'clicked': template == 'template2' }">
                  <img src="../assets/template2.png" />
                </div>  
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <template v-if="mytemplates.length == 0">
          <v-expansion-panel >
            <v-expansion-panel-header>My templates</v-expansion-panel-header>
            <v-expansion-panel-content>  </v-expansion-panel-content>
          </v-expansion-panel>
        </template> 
          <template v-else> 
            <v-expansion-panel v-for="(val,key) in mytemplates" :key="key">
              <v-expansion-panel-header>{{ val.template_name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <div class="template-imgs" >
                    <div class="temp-img" @click="showtemplate(val.template,val.template_image,'dynamic',val)" :class="{ 'clicked': template == val.template }">
                      <img :src=val.template_image />
                    </div>
                    <div class="text-center delete">
                      <span class="material-icons deletebtn" @click="deletetemplate(val.template_id)">delete</span>
                    </div>
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </div>
      <div class="main-content">
         <div v-if="template == ''" class="fill-blank-height">
             <div class="text-center pt-10 "><strong>Please choose template from left panel</strong></div>
         </div>
         <div v-if="template" class="maintemplate">
            <img :src=imgsrc />
         </div>
         <div class="action-buttons">
          <div class="btndivs">
            <v-btn type="submit" @click="next('first','firstload')" color="secondary">Previous</v-btn>  
          </div>
          <div class="btndivs">
            <v-btn type="submit" @click="next('third')" color="secondary">Next</v-btn>  
          </div>
       </div>        
      </div>
    </div> 
     <div class="container" v-if="third">
      <div class="election-dashboard">
       <div class="myrow mt-5">
         <div class="mycolfirst">Upload Voters</div>
         <div class="mycolsecond">
           <input type="file"  @change="handleFileChange($event)" />(Upload xls/csv only)
           <div class="errorclass" v-if="error.voters">{{ error.voters }}</div>
         </div> 
        </div>
        <div class="myrow" v-if="identities.length > 0">
         <div class="mycolfirst">
           <div>Identities</div>
         </div>
         <div class="mycolsecond" >
            <div class="identitieslist">
               <div v-for="(item,key) in identities" :key="key">
                 <v-checkbox
                 v-model="identitiesselected"
                 :label=item
                 :value=item
                 hide-details
                 class="diffchecks"
               ></v-checkbox> 
               </div>
            </div>
            <div class="errorclass" v-if="error.identitiesselected">{{ error.identitiesselected }}</div>
         </div>
        </div>
        <div class="action-buttons">
           <div class="btndivs">
             <v-btn type="submit" @click="next('second','firstload')" color="secondary">Previous</v-btn>  
           </div>
           <div class="btndivs">
             <v-btn type="submit" @click="next('fourth')" :disabled="makeitdisable" class="white--text" color="green">Confirm</v-btn>  
           </div>
        </div>
      </div>
    </div>
     <div class="container" v-if="fourth">
      <div class="election-dashboard">
        <div class="mt-5 text-center">
           <h4>Candidates Party/Logo</h4>
        </div> 
        <div class=" mt-5">
          <div class="text-center">
            <div
    class="upload-area"
    @click="triggerFileUpload"
    @dragover.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @drop.prevent="handleFileDrop"
    :class="{ 'drag-over': dragOver }"
  >
    <input type="file" ref="fileInput" @change="handlePartyLogos" style="display: none" multiple />
    <p v-if="!dragOver">Click or drag and drop to upload</p>
    <p v-else-if="dragOver">Drop the file here</p>
    <p v-if="partlogoerror" class="error">{{ partlogoerror }}</p>
   
  </div>
           
          </div> 
          <div class="text-center">(chooose mulitple photo of party/logo)</div>
          <div class="errorclass text-center" v-if="partlogoerror != ''">{{ partlogoerror }}</div>
         </div>
         <div class="myrow " v-if="imageUrls.length > 0">
          <div v-for="(url, index) in imageUrls" :key="index" style="margin: 5px; position:relative" >
            <img :src="url" alt="Image Preview" width="50">
            <button class="delete-btn" @click.stop="deleteImage(index)">✖</button>
          </div>
         </div>
         <div class="action-buttons">
            <div class="btndivs">
              <v-btn type="submit" @click="next('second','firstload')" color="secondary">Previous</v-btn>  
            </div>
            <div class="btndivs">
              <v-btn type="submit" @click="next('fifth')" :disabled="makeitdisable" color="secondary">Next</v-btn>  
            </div>
         </div>
       </div>
     </div>
    
  <div class="main-container" v-if="fifth">
    <div class="sidebarnew" v-if="showsidebar">
       <template v-for="(val,key) in tempdata" >
         <div :key="key">
          <Template1 v-if="template == 'template1'" @child-mounted="onChildMounted" :showimage=true :customStyles="childStyles" :preview=true :previewdata=val :showresult=true :vote=false />
          <Template2 v-if="template == 'template2'" @child-mounted="onChildMounted" :showimage=true :customStyles="childStyles" :preview=true :previewdata=val :showresult=true :vote=false  />
        </div>
      </template>
    </div>
    <div class="election-dashboard-small container-main">
      
      <div class="myrow">
         <Template1 v-if="template == 'template1'" :makedisable=customtemplate :previewdata=childdata ref="template1" :preview=false  :showresult=true :fields=true :votetype=votetype />
         <Template2 v-if="template == 'template2'" :makedisable=customtemplate :previewdata=childdata ref="template2" :preview=false :showresult=true :fields=true :votetype=votetype />
      </div>
      <div class="myrow" v-if="customtemplate">
        <div class="normalheading">Upload Candidates</div>
      </div>
      <div class="" v-if="customtemplate">
         <div class="text-center">
            <input type="file" @change="handleCandidate($event)" />
            <div class="errorclass" v-if="candidateerror">{{ candidateerror }}</div>
         </div>
         <div class="text-center smtext"><a :href="getFile()" target="_blank">Download sample file</a></div>
      </div>
      <div class="myrow" v-if="hidefornow">
      <div class="subdiv smallheads">Last Name</div>
      <div class="subdiv smallheads">First Name</div>
      <div class="subdiv smallheads">Party / Position</div>
      <div class="subdiv smallheads">
        <span v-if="template != 'template2'">Logo / Photo</span>
        
      </div>
      <div class="subdiv smallheads"></div>
      </div>
      <template v-if="hidefornow">
        <div class="myrow mb-5" v-for="(item, index) in items" :key="index">
          <div class="subdiv"><v-text-field hide-details outlined name="option" @blur="handleBlur" v-model="item.lastname"></v-text-field></div>  
          <div class="subdiv"><v-text-field hide-details outlined name="option" @blur="handleBlur" v-model="item.firstname"></v-text-field></div>  
          <div class="subdiv"><v-text-field hide-details outlined name="option" @blur="handleBlur" v-model="item.designation"></v-text-field></div>  
          <div class="subdiv">
            <img v-if="item.image" :src="convertimage(item.image)"  style="width: 25%;"/>
            <input v-if="template != 'template2'" type="file" @change="handleImageChange(index, $event)" />
          </div>  
          <div class="subdiv"> <v-btn color="red" class="white--text" v-if="index > 1" @click="removeItem(index)">Remove</v-btn></div>
        </div> 
      </template>
      <div class="errorclass" v-if="error.candidates">{{ error.candidates }}</div>
      <div class="text-center" v-if="hidefornow">
        <v-btn color="green" class="white--text"  @click="addItem()">Add More Candidate</v-btn>
      </div>
      <div class="myrow">
        <div class="mycolfirst">Bottom Notes</div>
        <div class="mycolsecond">
          <v-text-field outlined :disabled="this.disableinputs.bottomNotes" v-model="childdata['note']" hide-details ></v-text-field>
        </div>
     </div>
     
     <div class="myrow">
       <div class="mycolfirst">
          Ballot Paper Color
       </div>
       <div class="mycolsecond">
           <vue-color-picker v-if="!this.disableinputs.ballotColor" v-model="childdata['color']" />
           <div v-else class="defaultpicker" :style="{ backgroundColor: childdata['color'] }"></div>
       </div>
     </div>
     <div class="myrow">
      <div class="mycolfirst">
         Vote Type
      </div>
      <div class="mycolsecond">
        <v-radio-group v-model="votetype" :disabled="this.disableinputs.votetype">
          <v-radio label="Number (For multiple voting 1-100)" value="Number"></v-radio>
          <v-radio label="Color  (For single voting)" value="Color"></v-radio>
          <v-radio label="Cross  (For single voting)" value="Cross"></v-radio>
        </v-radio-group>
      </div>
    </div>
      <div class="action-buttons mt-8">
        <div class="btndivs">
          <v-btn type="button" @click="next('fourth','firstload')" color="secondary">Previous</v-btn>  
        </div>
        <div class="btndivs">

          <!--<v-btn type="button" @click="previewTemplate" class="white--text" color="red">Preview</v-btn>  -->
        </div>
        <div class="btndivs">
          <v-btn type="button" @click="captureImage" :disabled="makeitdisable" class="white--text" color="primary">Save Form</v-btn>  
          <div class="text-center red--text" v-if="saveError">{{ saveError }}</div>
        </div>
     </div>
     </div>
  </div>  
 
     <v-dialog  persistent max-width="300" v-model="loader">
      <v-card>
        <div v-if="showloadingmsg">
         <!--
           <div class="text-center">Please wait...</div> 
          <v-progress-linear indeterminate color="secondary"></v-progress-linear>
          -->
          <v-progress-linear
          v-model="progress"
          color="primary"
          height="25"
           class="non-clickable text-white"
        >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
         
        </v-progress-linear>
        </div>
        <v-card-text v-if="showerrorgmsg">
          <div class="text-center">{{ message}}</div> 
        </v-card-text>
      </v-card>
    </v-dialog>
   
    <v-dialog v-model="confirmdialog" persistent max-width="320">
       <v-card v-if="showdelbtns">
         <v-card-title class="small-text"> You want to delete this template ? </v-card-title>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="confirmdialog = false">No</v-btn>
            <v-btn color="green darken-1" text @click="confirmdelete">Yes</v-btn>
         </v-card-actions>
       </v-card>
       <v-card v-else>
          <div class="pt-5 pb-5 text-center">
            {{ wrongmsg }}
          </div>
       </v-card>
    </v-dialog>

  <v-dialog   width="300" v-model="popupmodal"  >
    <v-card>
      <v-toolbar
      color="primary"
      dark
    >Alert</v-toolbar>
      <v-card-text>
        <div class="text-center red--text mt-5">{{ popupmsg}}</div>  
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="popupmodal = false"
        >Close</v-btn>
      </v-card-actions>
  </v-card>
</v-dialog>
    <Popup v-if="isPopupOpen" @close="closePopup">
      <Template1 v-if="template == 'template1'" @child-mounted="onChildMounted" :customStyles="childStyles" :preview=true :previewdata=childdata :showresult=true :vote=false :votetype=votetype />
      <Template2 v-if="template == 'template2'" @child-mounted="onChildMounted" :customStyles="childStyles" :preview=true :previewdata=childdata :showresult=true :vote=false :votetype=votetype />
      <div class="saveTemplate" v-if="saveAsTemplate">
        <v-text-field outlined :disabled=maketemplatenamedisable label="Name of template" v-model="templatename" hide-details ></v-text-field>
        <div v-if="templateError != ''" class="errorclass">{{ templateError }}</div>
        <div class="mt-3">
          <v-btn type="button" :disabled="makeitdisable" @click="savetemplate" class="white--text" color="green">Save Template</v-btn>   
        </div>
      </div>
      <div class="saveTemplate" v-if="notchooseTemplate">
          {{ choosemsg }}
      </div>
    </Popup>
  </div>  
</template>
<script>
import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios';
import DatePick from 'vue-date-pick';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Template1 from '../Templates/Template1.vue';
import Template2 from '../Templates/Template2.vue';
import Popup from "../components/Popup.vue";
import html2canvas from 'html2canvas';


Vue.use(VueAxios, axios)
export default{
  name: 'Electionform',
  components: { VueTimepicker,DatePick, Template1, Template2,Popup},
  data(){
    return{
      popupmodal:false,
      popupmsg:'',
      dragOver: false,
      hidefornow:false,
      data:[],
      election_trans:0,
      countries:["America", "Australia", "United Kingdom","New Zeland"],
      timezone:'',
      timezoneList:[],
      country:'',
      electionstartdate:'',
      electionenddate:'',
      starttime:'',
      endtime:'',
      candidate:[],
      items: [],
      identities:[],
      identitiesselected:[],
      voters:null,
      first:true,
      second:false,
      third:false,
      fourth:false,
      fifth:false,
      error:{'electionstartdate':'','electionstarttime':'','electionendtime':'','candidates':'','voters':'','identitiesselected':''},
      loader:false,
      showheaders:false,
      showloadingmsg:true,
      showerrorgmsg:false,
      message:'',
      template:'',
      childdata : {
  candidates: [
    { image: "unknown.png", lastname: "Doe", firstname: "John", designation: "Unknown Party" },
    { image: "women.png", lastname: "Pankhurst", firstname: "Emmeline", designation: "Women’s Social Party" },
    { image: "pirate.png", lastname: "Kidd", firstname: "William", designation: "Pirate Party" },
    { image: "united.png", lastname: "Beckham", firstname: "David", designation: "United Party" },
    { image: "nurses.png", lastname: "Nightingale", firstname: "Florence", designation: "Nurses Party" }
  ]
},

      isPopupOpen: false,
      childStyles:{},
      htmlContent: null,
      saveAsTemplate:false,
      templatename:'',
      mytemplates:[],
      imgsrc:'',
      notchooseTemplate:false,
      choosemsg: '',
      templateError:'',
      defaultcolor:'#EEF6E7',
      votetype:'Number',
      makeitdisable:false,
      disableinputs:{'bottomNotes':false,'ballotColor':false, 'votetype':false},
      customtemplate:false,
      dynamictemplateData:{},
      maketemplatenamedisable:false,
      confirmdialog:false,
      deleteTemplate:0,
      selectedFiles: [],
      imageUrls: [],
      voteruploaded:'',
      showsidebar:false,
      tempdata:[],
      saveError:'',
      tempfile:'',
      partlogoerror:'',
      candidateerror:'',
      progress: 0,
      loading: false,
      showdelbtns:true, 
      wrongmsg:''
      
    }
  },
  created(){
     this.$root.$refs.Electiondashboard = this;
     this.initialData();
  },
  methods:{
    
    getFile(){
       if(this.template == "template1")
         return process.env.VUE_APP_BASEURL+"format1";
       else
         return process.env.VUE_APP_BASEURL+"format2";  
    },
    deleteImage(index) {
      this.imageUrls.splice(index, 1); // Remove the image at the specified index
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handlePartyLogos(event) {
      this.addFiles(event.target.files);
    },
    handleFileDrop(event) {
      this.dragOver = false;
      this.addFiles(event.dataTransfer.files);
    },
    addFiles(selectedFiles) {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"]; // Allowed image formats
      this.partlogoerror = ""; // Clear any previous errors

      Array.from(selectedFiles).forEach((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(extension)) {
          // Add file to selectedFiles for backend processing
          this.selectedFiles.push(file);
          // Generate preview URL and add to imageUrls
          this.imageUrls.push(URL.createObjectURL(file));
        } else {
          this.partlogoerror = "Please upload only image files (jpg, jpeg, png, gif)";
        }
      });
    },
    convertimage(file){
      return  URL.createObjectURL(file);
    },
    async next(action,firstload)
    {
      this.progress = 0;
       if(action == 'first')
       {
           this.first = true;
           this.second = false;
           this.third  = false;
           this.fourth = false;
           this.fifth = false;
       } 
       else if(action == 'second')
       { 
        this.getMyTemplates();
        if(this.electionstartdate == '')
           this.error.electionstartdate = 'Select a date of election';
         else
           this.error.electionstartdate = '';
        
         if(this.starttime == '')
           this.error.electionstarttime = 'Select a start time of election';
         else
           this.error.electionstarttime = '';
        
         if(this.endtime == '')
           this.error.electionendtime = 'Select a end time of election';
         else
           this.error.electionendtime = '';
         
         if(this.error.electionstartdate == '' && this.error.electionstarttime == '' && this.error.electionendtime == '' && (typeof firstload == 'undefined'))
         {
            this.loader         = true;
            this.showloadingmsg = true;
            this.showerrorgmsg  = false;
            let formData = new FormData();
            formData.append('eid', this.data.id);
            formData.append('election_trans_id', this.election_trans);
            formData.append('electionstartdate', this.electionstartdate);
            formData.append('electionenddate', this.electionenddate);
            formData.append('starttime', this.starttime);
            formData.append('endtime', this.endtime);
            formData.append('timezone',this.timezone);
            this.makeitdisable = true;
            Vue.axios.post('/save-election-data', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    }).then(response => 
            {
              this.makeitdisable = false;
              this.loader         = false;
              this.showloadingmsg = false;
              if(response.status)
              {
                /**/
                this.first = false;
                this.second = true;
                this.third  = false;
                this.fourth = false;
                this.fifth = false;
                if(this.election_trans == 0)
                {
                  this.election_trans = response.data.data;
                }
              } 
            }).catch(error => {
                console.log(error);
                this.makeitdisable = false;
            })    
         }  
         else
         {
           if(this.election_trans > 0)
           {
             this.first = false;
             this.second = true;
             this.third  = false;
             this.fourth = false;
             this.fifth = false;
           }
           else
           {
             this.$refs.scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
           }
         }  
       }
       else if(action == 'third')
       {
        
          if(this.template == '')
          {
            this.isPopupOpen = true;
            this.notchooseTemplate = true;
            this.choosemsg = 'Please choose a template from left panel';
          } 
          else
          {
            
            this.notchooseTemplate = false;
            this.choosemsg = '';
            if(this.voteruploaded == 'Y')
            {
              this.first  = false;
              this.second = false;
              this.third  = false;
              this.fourth = false; 
              this.fifth = true;
            }
            else
            {
              if(this.template == 'template1')
            {
              this.first  = false;
              this.second = false;
              this.third  = true;
              this.fourth = false; 
              this.fifth = false;
            }
            else
            {
              this.first  = false;
              this.second = false;
              this.third  = true;
              this.fourth = false; 
              this.fifth = false;
            }
            }
          }      
          
       } 
       else if(action == 'fourth')
       {
          if(this.voteruploaded == 'Y')
          {
             this.first  = false;
             this.second = false;
             this.third  = true;
             this.fourth = false; 
             this.fifth = false;
          }
          else
          {
            if(!this.voters || this.voters == null)
          {
            this.error.voters = 'Please upload xlsx or csv file';
          }
          if(this.identitiesselected.length == 0)
          {
            this.error.identitiesselected = 'Please choose atleast 2 identities';  
          }
          else
          {
            if(this.identitiesselected.length < 2)
              this.error.identitiesselected = 'Please choose atleast 2 identities';  
            else
              this.error.identitiesselected = '';
          } 
          
          var noerror = 1;
          if(this.error.voters == '' && this.error.identitiesselected == '')
              noerror = 0;
          else
              noerror = 1;
         
          if(this.election_trans > 0 && this.voteruploaded == 'Y')
          {
             noerror = 0;
          } 
          
          if(noerror == 0 && (firstload == '' || typeof firstload == 'undefined'))
          {
            this.makeitdisable = true;
            this.loader         = true;
            this.showloadingmsg = true;
            this.showerrorgmsg  = false;
            let formData = new FormData();
            formData.append('eid', this.data.id);
            formData.append('election_trans_id', this.election_trans);
            formData.append('voters', this.voters);
            formData.append('identities', this.identitiesselected);
            
            Vue.axios.post('/save-election-voters', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    }).then(response => 
            {
              this.makeitdisable = false;
              this.loader         = false;
              this.showloadingmsg = false;
              if(response.status)
              {
                if(this.template == 'template1')
              {
                this.first  = false;
                this.second = false;
                this.third  = false;
                this.fourth = true; 
                this.fifth = false;
              }
              else
              {
                this.first  = false;
                this.second = false;
                this.third  = false;
                this.fourth = false; 
                this.fifth = true;
              }
                // if(this.election_trans == 0)
                // {
                //   this.election_trans = response.data.data;
                // }
              } 
            }).catch(error => {
                console.log(error);
                this.makeitdisable = false;
            })  
          }
         
          if(typeof firstload != 'undefined' && firstload != '' )
          {
            
                this.first  = false;
                this.second = false;
                this.third  = true;
                this.fourth = false; 
                this.fifth = false;
          } 
          }
       } 
       else if(action == 'fifth')
       {
        
        if(this.template == 'template1')
        {
            var wait = 0;
           
           if(this.imageUrls.length > 0)
           {
            const formData = new FormData();
            this.selectedFiles.forEach((file, index) => {
                formData.append(`image_${index}`, file);
            });
            wait = 1;
            try 
            {
              formData.append('election_trans_id', this.election_trans);
              this.loader         = true;
              this.showloadingmsg = true;
              this.showerrorgmsg  = false;
              this.makeitdisable = true;
              Vue.axios.post("/upload-party-logo", 
                   formData, 
                   {headers: { "Content-Type": "multipart/form-data" },
                   onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
                   }).then((response) =>
                   {
                    this.loader         = false;
                    this.showloadingmsg = false;
                    this.makeitdisable = false;
                      if(response.status)
                      {
                        this.first  = false;
                        this.second = false;
                        this.third  = false;
                        this.fourth = false; 
                        this.fifth = true;
                      } 
                   });
              this.imageUrls = []; 
              this.selectedFiles = [];
            } 
            catch (error) 
            {
              console.error("There was an error uploading the images!", error);
            }
           } 
           else
           {
             wait = 1;
             this.partlogoerror = 'Please upload candidate party / logo';
           }
            
            if(wait == 0)
           {
            this.first  = false;
            this.second = false;
            this.third  = false;
            this.fourth = true; 
            this.fifth = false;
           }
        }  
        else
        {
            this.first  = false;
            this.second = false;
            this.third  = true;
            this.fourth = false; 
            this.fifth = false; 
        }   
       }
     
    },
    savetemplate() {
  if (this.htmlContent) {
    html2canvas(this.htmlContent, {
      useCORS: true,
      logging: false,
      allowTaint: true,
      scale: 2, // Improves image clarity
    }).then(canvas => {
      // Create a new canvas with an increased height
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = 355;
      resizedCanvas.height = 380; // Increased height to avoid stretching

      // Draw the original captured canvas onto the resized one
      ctx.drawImage(canvas, 0, 0, 355, 380);

      // Convert to an image
      const image = resizedCanvas.toDataURL('image/png');

      if (this.templatename !== '') {
        this.saveImageToServer(image);
      } else {
        this.templateError = 'Please provide a name for the template';
      }
    });
  } else {
    console.error('Child component not mounted yet.');
  }
}
,
    saveImageToServer(imageData) 
    {
        let myheading2 = '';
        let myheading3 = '';
        if(typeof this.childdata['data'].heading2 == "undefined")
          myheading2 = '';
        else
          myheading2 = this.childdata['data'].heading2;

        if(typeof this.childdata['data'].heading3 == "undefined")
          myheading3 = '';
        else
          myheading3 = this.childdata['data'].heading3;  

        this.makeitdisable = true;
        let customdata = {
          'color':this.childdata['color'],
          'data':{
                   'heading2':myheading2,
                   'heading3':myheading3,
                   'topheading':this.childdata['data'].topheading
                 },
          'note':this.childdata['note']
        };
       
       var templateid = 0; 
       var master     = 0;
       if(this.customtemplate) //it means that edit data
       {
          templateid = this.dynamictemplateData.template_id;
          master     = this.dynamictemplateData.master;
       }     
       
       let formData = new FormData();
       formData.append('candidates', JSON.stringify(this.items));
       formData.append('template', this.template);
       formData.append('template_data', JSON.stringify(customdata));
       formData.append('templateName', this.templatename);
       formData.append('eid', this.data.id);
       formData.append('election_trans_id', this.election_trans);
       formData.append('imageData', imageData);
       formData.append('votetype', this.votetype);
       formData.append('template_id', templateid);
       formData.append('master', master);
       formData.append('headerlogo1',this.childdata['data'].headerlogo1);
       formData.append('headerlogo2',this.childdata['data'].headerlogo2);
     
       Vue.axios.post('/save-template-data', 
                      formData, 
                      { 
                        headers: {'Content-Type': 'multipart/form-data' },
                        onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
                      }).then(response => 
                      {
                        if(response.status)
                        {
                          
                          window.location.reload();
                          
                          //this.closePopup();
                          //this.template = '';
                          //this.templatename = '';
                          //this.next('third','firstload');
                          //this.makeitdisable = false;
                          
                        }
                      }).catch(error => {
                          console.log(error);
                          this.makeitdisable = false;
                      })  
           
    },
    initialData()
    {
      this.$store.dispatch("checkElection",{url:this.$route.params.url}).then((response) => 
      {
       if(response.status)
       {
         this.country = response.data.country;
          
         this.data    = response.data;
         this.items.push({  lastname: " ", firstname: "",designation: "",image: null },{  lastname: " ", firstname: "",designation: "",image: null });
         this.childdata['color'] = this.defaultcolor;
         
         if(typeof response.data.election_data.start_date != 'undefined')
         {
          this.electionstartdate = response.data.election_data.start_date;
         }  
         
         if(typeof response.data.election_data.end_date != 'undefined')
         {
          this.electionenddate = response.data.election_data.end_date;
         }  

         if(typeof response.data.election_data.start_time != 'undefined')
         {
          this.starttime = response.data.election_data.start_time;
         }  

         if(typeof response.data.election_data.end_time != 'undefined')
         {
          this.endtime = response.data.election_data.end_time;
         }  
         this.timezoneList = response.data.timezones;
         
         if(typeof response.data.election_data.id != 'undefined')
         {
           this.election_trans = response.data.election_data.id;
           this.voteruploaded = response.data.election_data.voter_uploaded;
         
           
           if(response.data.election_data.voter_uploaded == "N")
           { 
              this.next('second','firstload');
           }
           else
           { 
              this.next('second','firstload');  
           }
         }  
         
       }
       else
       {
         this.$router.push({name:'home'});
       }
     })
    },
    previewTemplate(){
    
      if(this.saveAsTemplate)
        this.childStyles = this.getStyle();
      else
        this.childStyles = {};
     
      return new Promise(resolve => 
           {
              let childComponent = '';
              if(this.template == 'template1')
              {
                childComponent = this.$refs.template1;  
              }
              else if(this.template == 'template2')
              {
                childComponent = this.$refs.template2;  
              }

              childComponent.$on('child-data', data => {
                resolve(data);
              });
              
              childComponent.sendDataToParent(); // Trigger sending data from child
            }).then(data => 
              {
              this.childdata['data'] = data;
              if(this.customtemplate)
              {
                if(this.tempdata.length > 0)
                {
                   this.saveForm();
                } 
                else
                {
                   this.loader = true;
                   this.showloadingmsg = false;
                   this.showerrorgmsg = true;
                   this.message = "Please upload candidates";
                   setTimeout(() => { 
                    this.loader = false;
                    this.showerrorgmsg = false;
                    this.message = '';
                   }, 1500);
                }
              }
              else
              {
                this.isPopupOpen = true;
              }
            });
    },
    saveForm()
    {
      this.progress = 0;
      this.loader         = true;
      this.showloadingmsg = true;
      this.showerrorgmsg  = false;
      let formData        = new FormData();
      this.makeitdisable = true;
      formData.append('eid',this.data.id);
      formData.append('election_trans',this.election_trans);
      formData.append('master_template',this.dynamictemplateData.template_id);
      formData.append('candidates', this.tempfile);
      Vue.axios.post('/create-template-copy',formData,{headers: {'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    }).then(response => 
      {
        this.showloadingmsg = false;
        this.makeitdisable = false;
        if(response.data.status)
        {
          this.loader         = false;
          this.showloadingmsg = false;
          this.showerrorgmsg  = false;
          this.$router.push({ name: "electiondashboard" });
        }
        else
        {
          this.loader         = true;
          this.showerrorgmsg = true;
          this.message = response.data.msg;
          setTimeout(() => { 
          this.loader = false;
          this.showerrorgmsg = false;
          this.message = '';
          }, 1500);
        } 
      });   
    },
    gotoTop(){
      this.$refs.scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    onChildMounted(childElement) {
      this.htmlContent = childElement;
    },
    captureImage()
    {
      var noerrorhere = ''; 
      this.error.candidates = '';
       
      if(this.customtemplate && this.dynamictemplateData.master > 0)
      {
        for(var i = 0; i < this.items.length; i++)
        {
           if(this.items[i].firstname == '' || this.items[i].lastname == '')
           {
             noerrorhere = 'error';
           }
        }
      }
      if(noerrorhere == '')
      {
         this.saveAsTemplate = true;
         this.previewTemplate();
      }
      else
      {
        this.error.candidates = 'Please provide atleast 2 candidates details';
        this.$refs.scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    getStyle(){
      return {position: 'absolute', left: '-9999px'}
    },
    closePopup() {
      this.isPopupOpen = false;
      this.saveAsTemplate = false;
    },
    showtemplate(template,src,type,customdata){
      this.template = template;
      this.childdata['color'] = this.defaultcolor;
      if(type == 'static')
      { 
         this.customtemplate = false;
         this.imgsrc   = require('../assets/'+src);
         this.childdata['data'] = [];
         this.childdata['note']  = '';
         this.dynamictemplateData = {};
         this.templatename = '';
         this.maketemplatenamedisable = false;
         this.items = [{  lastname: " ", firstname: "",designation: "",image: null },{  lastname: " ", firstname: "",designation: "",image: null }];
         Object.keys(this.disableinputs).forEach(key => { this.disableinputs[key] = false; });
      }
      else
      { 
       
         this.imgsrc   = src;
         this.customtemplate = true;
         this.dynamictemplateData = customdata;
         
         if(customdata.master > 0)
         { 
           this.maketemplatenamedisable = true;
           this.templatename = this.dynamictemplateData.template_name;
         }
         else
         {
           this.maketemplatenamedisable = false;
           this.templatename = '';
         } 
         
         Object.keys(this.disableinputs).forEach(key => { this.disableinputs[key] = true; });
         
         if(typeof customdata.data.vote_type != 'undefined')
         {
           this.votetype = customdata.data.vote_type;
         }
         
         if(typeof customdata.data.data != 'undefined')
         {
           this.childdata['data'] = customdata.data.data;
         }
         if(typeof customdata.data.color != 'undefined')
         {
           this.childdata['color'] = customdata.data.color;
         }
         if(typeof customdata.data.note != 'undefined')
         {
           this.childdata['note'] = customdata.data.note;
         }
         
         if(typeof customdata.candidates != 'undefined')
         {
           if(customdata.candidates.length > 0)
            { 
               this.items = customdata.candidates;
               this.childdata['candidates'] = customdata.candidates;
            }
           else
            {
              this.items = [{  lastname: " ", firstname: "",designation: "",image: null },{  lastname: " ", firstname: "",designation: "",image: null }];
            } 
         }
         //this.childdata['color'],'data':this.childdata['data'],'note':this.childdata['note']
       }
    },
    handleBlur(){
      this.childdata['candidates'] = this.items;
    },
    isFutureDate(date) {
            const currentDate = new Date();
            return date < currentDate;
        },
    addItem() {
      this.items.push({  lastname: "", firstname: "",designation: "",image: null });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    handleCandidate(event){
      const file = event.target.files[0]; 
      this.progress = 0;
      this.candidateerror = '';
      if (file) 
      {
        if(file.size > 0)
        {
          if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv') 
          {
            this.loader = true;
            this.showloadingmsg = true;
            this.showerrorgmsg = false;
            let candidates = event.target.files[0];
            let formData = new FormData();
            this.tempfile = candidates;
            formData.append('candidates', candidates);
            formData.append('election_trans',this.election_trans);
            Vue.axios.post('/validate-candidates',formData,{headers: {'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
          }).then(response => 
            {
              if(response.data.status)
              {
                if(response.data.result.length > 0)
               {
                this.tempdata = [];
                 for(var i = 0; i < response.data.result.length; i++)
                 {
                    let newdata = response.data.result[i];
                   
                    this.tempdata.push({
                          candidates   : newdata.candidates,
                          data         : newdata.data.data,
                          color        : newdata.data.color, 
                          note         : newdata.data.note, 
                          template_name: newdata.template_name 
                        });
                    //this.tempdata.push()
                 }
                 console.log(this.tempdata);
               }
               //console.log(Array.isArray(this.tempdata));
                //vikasvyas
                this.showsidebar = true;
                //this.childdata['candidates'] = response.data.data;
                //this.items = response.data.data;
                //this.previewTemplate();
              }
              else
              {
                 this.popupmodal = true;
                 this.popupmsg = response.data.msg;
              }
              this.loader = false;
            }).catch(() => {
              this.loader = false;
            }) 
          }
          else
          {
            this.candidateerror = 'Please upload only xlsx or csv file';
          }  
        }
      }      
    },
    handleFileChange(event)
    {
      const file = event.target.files[0]; 
      this.progress = 0;
      if (file) 
      {
        if(file.size > 0)
        {
          if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv') 
          {
            this.loader = true;
            this.showloadingmsg = true;
            this.showerrorgmsg = false;
            this.voters = event.target.files[0];
            this.error.voters = '';
            let formData = new FormData();
            formData.append('voters', this.voters);
            Vue.axios.post('/validate-file-headers', 
                              formData, 
                              { 
                                headers: {'Content-Type': 'multipart/form-data' },
                                onUploadProgress: (progressEvent) => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
                              }).then(response => 
                              {
                                if(response.data.status)
                                {
                                  this.identities = response.data.data;
                                }
                                this.loader = false;
                              }).catch(() => {
                                this.loader = false;
                              })  
          } 
          else
          {
            this.error.voters = 'Please upload only xlsx or csv file';
          }  
        }
      }
     
    },
    handleImageChange(index, event) {
      const file = event.target.files[0];
      this.items[index].logo = file;
      /*const reader = new FileReader();

      reader.onload = () => {
        this.items[index].image = reader.result;
        
      };
      
      if (file) {
        reader.readAsDataURL(file);
      }*/
      this.handleBlur();
    },
    getMyTemplates(){
      this.$store.dispatch("getmyTemplates").then((response) => { this.mytemplates = response.data;   });
    },
    deletetemplate(id){
      this.showdelbtns = true; 
      this.confirmdialog = true;
      this.deleteTemplate = id;
    },
    confirmdelete(){
      this.$store.dispatch("deleteTemplate",{id:this.deleteTemplate}).then((response) => 
       {
          if(response.status)
          {
            this.getMyTemplates();
            this.confirmdialog = false;
            this.deleteTemplate = 0;
          }
          else
          {
             this.wrongmsg = response.msg;
             this.showdelbtns = false;
             this.timeoutId = setTimeout(() => {
              this.confirmdialog = false;
             
              }, 5000);
          }
       })
    }
  }
}
</script>
<style scoped>
.topdiffdiv{margin-top: 30px;}
.election-heading{margin-top: 10px; margin-bottom: 10px; font-size: 20px; font-weight: bold; text-align: center;}
.election-dashboard{box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; padding: 10px 15px; border-radius: 10px; width: 80%; margin: auto; margin-bottom: 20px;}
.election-dashboard-small{box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; padding: 10px 15px; border-radius: 10px; width: 70%; margin: auto; margin-bottom: 20px;}
.myrow{display: flex; align-items: center; margin-top: 15px; margin-bottom: 10px; flex-wrap: wrap;}
.mycolfirst{flex: 0 0 18%; font-weight: bold;}
.mycolsecond{flex: 0 0 40%;}
.normalheading{text-align: center; font-weight: 500; width: 100%; margin-top: 10px;}
.subdiv{width: 22%; margin-right: 10px; margin-left: 10px;}
.subdiv:nth-child(4){width: 11%; overflow: hidden;}
.subdiv:nth-child(5){width: 10%;}
.infor{font-size: 13px;}
.smallheads{text-align: center; font-size: 13px; font-weight: bold;}
.action-buttons{margin-top: 15px; display: flex; justify-content: space-between;}
.identitieslist{height: 180px; overflow-y: scroll; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 10px; border-radius: 10px;}
.custom-headings{text-align: center; margin-top: 15px; margin-bottom: 15px; font-weight: bold;}
.templates {display: flex;text-align: center;  justify-content: space-between; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.temp-img{cursor: pointer;}
.template-imgs{ margin-top: 10px; text-align: center;}
.diff-container{ display: flex; margin-bottom: 5%;}
.main-container {display: flex;  justify-content: space-between;  padding: 10px;}
.sidebar {flex-basis: 300px; padding: 20px; border-right: 1px solid #666363;}
.main-content { flex-grow: 1; padding: 20px;}
.fill-blank-height{min-height: 550px;}
.clicked img{border: 2px solid #2623c4; cursor: pointer;}
.saveTemplate{padding: 15px; border: 1px solid #666363; width: 300px; background: #fff; margin: auto; text-align: center;}
.temp-img img{width: 160px; height: 180px;}
.maintemplate{text-align: center;}
.deletebtn{color: rgb(99, 27, 27); cursor: pointer;}
.small-text{font-size: 15px !important;}
.upload-area {

  width: 300px;
  min-height: 200px;
  background-image: url('../assets/upload.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border: 2px dashed #ccc;
  text-align: center;
  transition: background-color 0.2s;
  flex-direction: column;
  margin: auto
}

.upload-area.drag-over {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #fff;
}
.delete-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.makerelative{position: relative;}
/* Responsive styles */
@media screen and (max-width: 768px) {
  .main-container {flex-direction: column-reverse;}
  .sidebar { flex-basis: 100%;  margin-bottom: 20px; }
}
@media(max-width:767px)
{
  .election-dashboard{width: 98%;}
  .templates{flex-direction: column; }
  .template-part{width: 100%;margin-bottom: 20px;}
}  
</style>
<style>
 .myexpansion .v-expansion-panel-header{padding: 15px 15px; font-weight: bold;}
 .diffchecks.v-input--selection-controls{margin-top: 0;}
 .errorclass{color: red; font-size: 12px; margin-top: 3px;}
 .cpicker{width: 60px; height: 30px; border: 2px solid #000;}
 .cpicker-title{font-size: 0px;}
 img.smalldemoimg{ width: 45px;}
.defaultpicker{width: 60px; height: 30px; border: 2px solid #000;}
.smtext{font-size: 12px;}
.container-main{width: 70%;}
.sidebarnew{flex-basis: 20%;height: 790px; overflow-y: scroll; padding: 15px;}
</style>
<style>
.imageprev img { width: 100%;}
.tempnamespan{text-align: center; font-weight: bold;}
.non-clickable {
  pointer-events: none;
}
</style>